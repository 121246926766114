import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LocalazyMeta from "./localazy-meta";

import LanguageDetector from "i18next-browser-languagedetector";
import DE from "./de/translation.json";
import EN from "./en/translation.json";

export const ENLang = EN;
export const DELang = DE;

export const getSupportedLangCodes = () => {
  return LocalazyMeta.languages.map((l) => l.language);
};

export const supportedLangCodes = getSupportedLangCodes();

export const getBaseLanguage = () => {
  return LocalazyMeta.baseLocale;
};

export const getCurrentLanguage = (): string => {
  if (typeof window !== "undefined") {
    return (window.localStorage as any).i18nextLng || "en";
  }
  return "en";
};

export const getLanguages = () => {
  return LocalazyMeta.languages;
};
// the translations
const resources = {
  en: { translation: EN },
  de: { translation: DE },
};
i18n

  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: getBaseLanguage(),
    nsSeparator: "__",
    debug: false,

    keySeparator: "__",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    supportedLngs: getSupportedLangCodes(),
    resources,
    react: {
      useSuspense: false,
    },
  });

export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang, (err) => {
    if (err) throw err;
    window.localStorage.setItem("i18nextLng", lang);
  });
};

export default i18n;
