const localazyMetadata = {
  projectUrl: "https://localazy.com/o/grune-brise",
  baseLocale: "en",
  languages: [
    {
      language: "en",
      region: "",
      script: "",
      isRtl: false,
      name: "English",
      localizedName: "English",
      pluralType: (n: number) => {
        return n === 1 ? "one" : "other";
      },
    },
    {
      language: "de",
      region: "",
      script: "",
      isRtl: false,
      name: "German",
      localizedName: "Deutsch",
      pluralType: (n: number) => {
        return n === 1 ? "one" : "other";
      },
    },
  ],
};

export default localazyMetadata;
